import React from "react";


import "./templateHeader.css";
import "./../../css/base.css";
import { NavLink } from "react-router-dom";

class TemplateHeader extends React.Component {

  constructor(props) {
    super(props);
    this.title = this.props.title;
    
  }

  render() {
    return <header>
      
      <div className="heading">
        <i className="schul-logo"></i>

        <h1>
          <span>{this.title}</span>
        </h1>
      </div>

      {
        this.props.hasLink ? <NavLink to="/weiteres" className="weiteres-link">
          Über die Entwickler
        </NavLink> : null
      }

    </header>
  }


}

export default TemplateHeader;