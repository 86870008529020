import React from "react";
import { NavLink } from "react-router-dom";
import TemplateFooter from "../../components/templateFooter/templateFooter";
import TemplateHeader from "../../components/templateHeader/templateHeader";

import "./confirmationView.css";

class ConfirmationView extends React.Component {

  constructor(props) {
    super(props);
  }
  
  render() {
    return <div>
    
      <TemplateHeader title="Bestätigung"></TemplateHeader>

      <main className="confirmation-main">
        <h1> Ihre E-mail wurde erfolgreich abgeschickt </h1>
        <article>
          <p>In den nächsten Minuten wird Ihnen eine Bestätigungs-E-mail zugesendet.</p>
          <p>Bitte halten sie in Ihrem Postfach und Spam-Ordner Ausschau.</p>
        </article>
        <article>
          <p>Die Informationen des Antrags werden an die Schulleitung und dem Förderverein weitergeleitet.</p>
          <p>Wir bitten um Geduld.</p>
        </article>

        <NavLink to="/">Zurück zur Startseite</NavLink>
      </main>

      <TemplateFooter />

    </div>;
  }

}


export default ConfirmationView;