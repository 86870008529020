import React, { useRef, useState } from "react";
import TemplateHeader from "../../components/templateHeader/templateHeader";
import TemplateFooter from "../../components/templateFooter/templateFooter";
import TemplateButton from "../../components/templateButton/templateButton";

import "./authorizationView.css";        
import { useLocation, useNavigate } from "react-router-dom";

const AuthorizationView = () => {

    const [ anzahlVersuche, setAnzahlVersuche ] = useState("3");
    const [ statusMessage, setStatusMessage ] = useState("");
    const sessionId = useLocation().state.session_id;
    const iban = useLocation().state.iban;
    const navigate = useNavigate();


    //console.log(sessionId);

    /**
     * 
     * Diese Methode wird genutzt, wenn man auf den "Abschließen"-Button drückt
     * -> soll die fetch api von javasript nutzen um eine Anfrage an PHP zu senden, sodass das backend
     * -> den eingegebenen code mit dem generierten code vergleichen kann. 
     * -> falls der Code übereinstimmt (php gibt true zurück, so soll zu der confirmationPage gewechselt werden -> useNavigate hook)
     * -> falls der Code nicht übereinstimmt, so soll die Anzahl der Versuche durch setState() dekrementiert werden.
     * 
     * @param {event} clickevent von dem button
     * @return {} keine
     * 
     * 
     */
    const codeVergleich = (e) => {

      //!! nicht löschen!
      const formData = new FormData();

      formData.append("sessionId", sessionId);
      formData.append("iban", iban);
      formData.append("code", document.getElementById("code").value);

      // TODO: remove during production
      fetch(`/backend/codeInput.php`, {
        method:"POST",
        body: formData,
      })
      .then( async (res) => {
        const response = await res.json();
        console.log(response);

        /// siehe https://github.com/TotallyInformatik/formular-fv-backend
        switch (res.status) {
          case 200:
            navigate("/confirmation");
            break;
          case 400:

            switch (response["internalStatusCode"]) {
              case 34:
                setAnzahlVersuche("0");
                setStatusMessage("Maximale Anzahl der Versuche überschritten. Bitte gehen sie zurück auf die Startseite und versuchen Sie erneut.");
                break;
              case 32:
                setAnzahlVersuche(response["statusMessage"]);
                setStatusMessage("Falscher Code.");
                break;
            }
            break;
          case 410:
            setAnzahlVersuche(0);
            setStatusMessage("Maximale Anzahl der Versuche überschritten. Bitte gehen sie zurück auf die Startseite und versuchen Sie erneut.");
            break;
        }


      })
      .catch((error) => console.log(error))
      
    }

    /**
     * 
     * Diese Methode soll die fetch-api von javascript nutzen, um eine php anfrage zu schicken, sodass ein neuer
     * code generiert wird.
     * 
     * @param {event} clickevent vom Button 
     * @return nichts
     * 
     */
    const codeAnfordern = (e) => {
      //!! nicht löschen!
      const formData = new FormData();
      formData.append("sessionId", sessionId);

      fetch(`/backend/resendCode.php`, {
        method:'POST',
        body: formData
      }).then((res) => {
        console.log(res.json());

        switch (res.status) {
          case 200:
            setAnzahlVersuche("3");
            setStatusMessage("Neuer Code versendet.");
            break;
          case 400:
            setStatusMessage("Das hat leider nicht geklappt. Bitte versuchen Sie es nochmal.");
            break;
        }

      })

    }


    /**
     * 
     * TODO: bitte noch eine Anzeige für die Anzahl der Versucht mit state
     * 
     */

    return <div>
    
      <TemplateHeader title="Authentisierung"></TemplateHeader>

      <main>
          <p>Zur Verifikation wurde ein sechsstelliger Code an ihre E-Mail-Addresse gesendet. Falls Sie diese nicht finden, so gucken Sie bitte auch im Spam Ordner nach.</p>
          <p> Bitte geben Sie diesen Code zum Abschluss des Antrags unten ein. Nach drei falschen Versuchen wird der Code invalidiert und Sie müssen einen erneuten Versuch starten.</p>

          <div className="field">
            <label htmlFor="code">Erhaltener Code</label>
            <input type="text" id="code" placeholder="Code eingeben"></input>
          </div>

          <section className="buttons">
            <TemplateButton onClick={(e) => codeVergleich(e)} display={"Abschließen"}></TemplateButton>
            <TemplateButton className="erneut-senden" onClick={(e) => codeAnfordern(e)} display={"Erneut Senden"}></TemplateButton>
          </section>

          <section className="status">
            <p className="versuche">Übrige Versuche: {anzahlVersuche}</p>
            <p className="status-message">{statusMessage}</p>
          </section>

      </main>

      


      <TemplateFooter />

    </div>;
  
  }
  
  
export default AuthorizationView;