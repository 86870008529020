import React from "react";

import "./templateButton.css";

class TemplateButton extends React.Component {

  constructor(props) {
    super(props);
    this.onClick = this.props.onClick.bind(this);
    this.display = this.props.display;
  }

  render() {
    return <input type="submit" className={"template-button " + this.props.className} onClick={this.onClick} value={this.display} />;
  }

}

export default TemplateButton;