import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TemplateHeader from "../../components/templateHeader/templateHeader";
import TemplateButton from "../../components/templateButton/templateButton";

import "./applicationView.css";
import TemplateFooter from "../../components/templateFooter/templateFooter";
import { useNavigate } from "react-router-dom";

const ApplicationView = () => {
  const navigate = useNavigate();
  const recaptchaRef = useRef();

  const validateCaptcha = async (token) => {

    //TODO: replace domain name
    await fetch(`/backend/validateCaptcha.php`, {
      method: "POST",
      body: JSON.stringify({
        token: token,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then(async (res) => {
        const statusMessage = (await res.json())["statusMessage"];
        if(statusMessage == "pass") {
          sendApplication();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendApplication = async () => {

    // Speichern der eingegebenen Werte in Variablen
    var antragsteller = document.getElementById("antragsteller").value;
    var email = document.getElementById("email").value;
    var tel = document.getElementById("tel").value;
    var zeitpunkt = document.getElementById("zeitpunkt").value;
    var massnahme = document.getElementById("massnahme").value;
    var erlaeuterung = document.getElementById("erlaeuterung").value;

    //* Iban wird seperat lokal gespeichert. Sicherheit.
    var iban = document.getElementById("iban").value;


    // Speichern der Daten in einem Form-Objekt
    const submission = new FormData();
    submission.append("antragsteller", antragsteller);
    submission.append("email", email);
    submission.append("tel", tel);
    submission.append("zeitpunkt", zeitpunkt);
    submission.append("massnahme", massnahme);
    submission.append("erlaeuterung", erlaeuterung);

    // Senden der Daten an den Server
    fetch(`/backend/sendCode.php`, {
        method: "POST",
        body: submission,
      }
    ).catch((error) => {
      document.getElementById("form-status").innerText = "Etwas ist schiefgelaufen. Bitte versuchen Sie nochmal. Versuchen Sie auch, die Seite zu aktualisieren.";
    }).then(async (res) => {

      const jsonResponse = await (res.json());
      const sessionId = jsonResponse["statusMessage"];

      if (res.status == 200) {
        navigate("/authorization", {
          state: {
            session_id: sessionId,
            iban: iban
          }
        });
      }
      if (res.status == 400) {
        document.getElementById("form-status").innerText = "Etwas ist schiefgelaufen. Bitte versuchen Sie nochmal. Versuchen Sie auch, die Seite zu aktualisieren.";
      }
    });
  }

  const executeCaptcha = async (e) => {
    e.preventDefault();
    await recaptchaRef.current.execute();
  }


  return (
    <div>
      <TemplateHeader
        title="Antrag auf Fördermittel"
        hasLink={true}
      ></TemplateHeader>
      <main>

    
      <p>
        Die mit * bezeichneten Felder sind Pflichteingaben
      </p>

        <form onSubmit={(e) => {
            e.preventDefault();
            executeCaptcha(e);
          }}>
          <section>
            <div className="field applicant">
              <label htmlFor="antragsteller">
                Antragsteller, ggf. Abteilung *
              </label>
              <input
                type="text"
                id="antragsteller"
                placeholder="Max Mustermann"
                required
              ></input>
            </div>

            <div className="field email">
              <label htmlFor="email">E-Mail *</label>
              <span>
                <input
                  type="text"
                  id="email"
                  placeholder="max.mustermann"
                  required
                ></input>
                <span>@schule.duesseldorf.de</span>
              </span>
            </div>

            <div className="field tel">
              <label htmlFor="number">Telefon Nummer</label>
              <input type="tel" id="tel" placeholder="01575 0293857"></input>
            </div>

            <div className="field time">
              <label htmlFor="zeitpunkt">Geplanter Zeitpunkt/Schuljahr *</label>
              <input
                type="text"
                id="zeitpunkt"
                placeholder="2020 / 2021"
                required
              ></input>
            </div>

            <div className="field measure">
              <label htmlFor="massnahme">Maßnahme in Stichworten *</label>
              <input
                type="text"
                id="massnahme"
                placeholder="Maßnahme 1, Maßnahme 2"
                required
              ></input>
            </div>

            <div className="field details">
              <label htmlFor="erlaeuterung">Detaillierte Erläuterungen *</label>
              <textarea
                name="erlaeuterung"
                id="erlaeuterung"
                placeholder="Hier eingeben..."
                required
              ></textarea>
            </div>

            <div className="field iban">
              <label htmlFor="iban">IBAN</label>
              <input
                type="text"
                id="iban"
                placeholder="DE00 0000 0000 0000"
              ></input>
            </div>

            <input className="submit" type="submit" value="Weiter" />

            <p className="form-status">

            </p>

          </section>

          <ReCAPTCHA
            sitekey="6Ld2BIofAAAAALrdbkauL-GwqCOw0bluDXEOpcru"
            ref={recaptchaRef}
            onChange={(token) => validateCaptcha(token)}
            size="invisible"
          />
          <div>
          </div>
        </form>
      </main>
      <TemplateFooter />
    </div>
  );
};

export default ApplicationView;
