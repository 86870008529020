import React from 'react';
import ReactDOM from 'react-dom';

import { Route, BrowserRouter as Router, Routes, NavLink, HashRouter } from 'react-router-dom';

// Components
import ApplicationView from './applicantView/applicationView/applicationView';
import ConfirmationView from './applicantView/confirmationView/confirmationView';
import AuthorizationView from './applicantView/authorizationView/authorizationView';
import AboutView from './applicantView/aboutView/aboutView';
import ErrorView from './applicantView/404View/404View';

ReactDOM.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<ApplicationView />}></Route>
      <Route path="/confirmation" element={<ConfirmationView />}/>
      <Route path="/authorization" element={<AuthorizationView/>}/>
      <Route path="/weiteres" element={<AboutView/>}/>
      <Route path="*" element={<ErrorView/>}></Route>
    </Routes>
  </HashRouter>,
  document.getElementById('root')
);

