import React from "react";

import "./templateFooter.css";

class TemplateFooter extends React.Component {

  render() {
    return <footer>
      <h3>
        Von der Annette-Entwickelt-Software Arbeitsgemeinschaft mit Leidenschaft entwickelt
      </h3>
    </footer>
  }

}

export default TemplateFooter;