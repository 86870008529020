import React from "react";

class ErrorView extends React.Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return <main>
             <h1>404</h1>
             <h2>Not Found</h2>
             <h3>Der Link konnte nicht gefunden werden. Bitte kehren Sie zur Hauptseite zurück</h3>
        </main>;
    }
}

export default ErrorView;


