import React from "react";
import { NavLink } from "react-router-dom";
import TemplateFooter from "../../components/templateFooter/templateFooter";
import TemplateHeader from "../../components/templateHeader/templateHeader";



import "./aboutView.css";

class AboutView extends React.Component {

    constructor(props) {
      super(props);
    }

    render() {
        return <div>
          <TemplateHeader title="About Annette-Entwickelt-Software" hasLink={false} />
          <main className="flex">
            <section className="text-section">
              <h1>Wir sind die Annette-Entwickelt-Software AG</h1>
              <p>
                Die Annette-Entwickelt-Software AG besteht aus einer Gruppe von enthusiastischen Programmierern
                und arbeitet leidenschaftlich und engagiert an 
                diversen informatischen Projekten wie die Annette-App. 
              </p>
              <p>
                Auch das Online-Antrags-Formular des Fördervereins (diese Seite) hat die AG erstellt. 
              </p>
              <p>
                <b>Für Projekte, Ideen und / oder Beschwerden können Sie / könnt Ihr uns direkt per <a href="mailto:annettesoftware@gmail.com">Email</a></b> erreichen.
                Je nach Aufwand und Kapazität werden diese auch umgesetzt! Diese können auch einen offizielleren Rahmen besitzen.
                Wir sind immer bereit für Herausforderungen und werden garantiert Ergebnisse liefern.
              </p>
              <p>Die Folgenden SuS haben an diesem Projekt mit viel Engagement und Fleiß gearbeitet</p>
              <div>
                  <ul>
                      <li><a href="https://website-totallyinformatik.vercel.app/">Rui Zhang</a></li>
                      <li><a href="http://janw.bplaced.net/">Jan Wermeckes</a></li>
                      <li>Arwed Walke</li>
                      <li>Elias Dörr</li>
                      <li>Yonas Lalouh</li>
                      <li>David Kanevski</li>
                      <li>Toni Lindenau</li>
                      <li>Liam Kunert</li>
                  </ul>
              </div>
              <NavLink to="/">Zurück zur Startseite</NavLink>
            </section>
            <section className="big-text-section">
              <h1>
                Annette
                Entwickelt
                Software
              </h1>
            </section>
          </main>
          <TemplateFooter />
        </div>;
    }
}

export default AboutView;